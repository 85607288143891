var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},_vm._l((_vm.games.filter(
      function (g) { return (_vm.idteam1 === g.player1_id && _vm.idteam2 === g.player2_id) ||
        (_vm.idteam1 === g.player2_id && _vm.idteam2 === g.player1_id); }
    )),function(game){return _c('div',{key:game.game_id,staticClass:"game-score col-6 p-0"},[(
        _vm.idteam1 === game.player1_id && _vm.idteam2 === game.player2_id
      )?_c('div',{staticClass:"py-1"},[(game.score_side1 > game.score_side2)?_c('span',{staticClass:"score-block"},[_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])]):(game.score_side1 < game.score_side2)?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side2))])]):(
          game.score_side1 === game.score_side2 && game.state === 'finished'
        )?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])]):_c('span',[_c('b',{staticClass:"separator text-white-50"},[_vm._v("-")])])]):(
        _vm.idteam1 === game.player2_id && _vm.idteam2 === game.player1_id
      )?_c('div',{staticClass:"py-1"},[(game.score_side2 > game.score_side1)?_c('span',{staticClass:"score-block"},[_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side2))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))])]):(game.score_side2 < game.score_side1)?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{class:_vm.dataSport.textClass},[_vm._v(_vm._s(game.score_side1))])]):(
          game.score_side2 === game.score_side1 && game.state === 'finished'
        )?_c('span',{staticClass:"score-block"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side1))]),_c('span',{staticClass:"separator text-white"},[_vm._v("-")]),_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(game.score_side2))])]):_c('span',[_c('b',{staticClass:"separator text-white-50"},[_vm._v("-")])])]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }